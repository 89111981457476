import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '~/app/hooks';
import { useGetLendioAppInitDataQuery } from '~/app/services/lendio';
import { useLazyLogMessageQuery } from '~/app/services/logger';
import { Loader } from '~/components/Loader';
import { Modal } from '~/components/Modal';
import { selectCurrentUser } from '../Auth/authSlice';
import GlobalLendio from './types/GlobalLendio';

const LendioLanding = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showErrorModal, setError] = useState(false);
  const qFormGuid = searchParams.get('guid');
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const lendio = (window as GlobalLendio & typeof globalThis).lendio;
  const [logMessage] = useLazyLogMessageQuery();
  const { isFetching, isSuccess, isError, data: lendioInitData } = useGetLendioAppInitDataQuery({
    qFormGuid: qFormGuid ?? '',
    email: currentUser?.id ?? '',
  });

  const log = useCallback(
    (msg: string) => {
      logMessage({ qFormGuid: qFormGuid, id: 'c25637e2b00e', message: msg });
    },
    [qFormGuid, logMessage]
  );

  useEffect(() => {
    log('Lendio: Component mounted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log('Lendio: Before lendio calls before lendioInitData check');
    log(`Lendio: Scripts installed boolean value: ${lendio.scriptsInstalled}`);
    if (!isFetching && isSuccess && lendioInitData) {
      log(`Lendio: QF from data ${lendioInitData.user.login}`);
      // Verify correct user sees the correct application
      log('Lendio: Checking user email');
      if (currentUser?.id === lendioInitData.user.email) {
        log('Lendio: Before Init');
        lendio.initialize(lendioInitData);
        log('Lendio: Before Application launch');
        lendio.launchLoanApplication();
      } else {
        log('Lendio: Set error true');
        setError(true);
      }
    }
  }, [isFetching, isSuccess, lendioInitData, currentUser, lendio, log]);

  useEffect(() => {
    if (showErrorModal || isError)
      setTimeout(() => {
        navigate('/logout', { replace: true });
      }, 3000);
  }, [showErrorModal, isError, navigate]);

  // Handle events returned by Lendio app
  useEffect(() => {
    lendio.setEventHandler(
      'authenticationFailed',
      () => {
        log('Lendio: Authentication Failed');
        navigate('/logout');
      },
      1
    );

    lendio.setEventHandler(
      'close',
      () => {
        log('Lendio: Experience closed');
        navigate('/logout');
      },
      1
    );

    lendio.setEventHandler(
      'applicationInitialized',
      () => {
        log('Lendio: Application initialized successfully');
      },
      1
    );

    lendio.setEventHandler(
      'launchedLoanApplication',
      () => {
        log('Lendio: Application was launched successfully');
      },
      1
    );

    lendio.setEventHandler(
      'iframeStylesReady',
      () => {
        log('Lendio: Lendio iframe styles ready');
      },
      1
    );

    lendio.setEventHandler(
      'wrapperAppInstalled',
      () => {
        log('Lendio: Wrapper App Installed');
      },
      1
    );

    lendio.setEventHandler(
      'initializationFailure',
      () => {
        log('Lendio: App initialization failed');
        navigate('/logout');
      },
      1
    );

    lendio.setEventHandler(
      'userAuthenticated',
      () => {
        log('Lendio: User Authentication successful');
      },
      1
    );

    lendio.setEventHandler(
      'applicationSubmitted',
      () => {
        log('Lendio: Application submitted');
      },
      1
    );

    lendio.setEventHandler(
      'termsAndConditionsCheckboxSelected',
      () => {
        log('Lendio: Term checkbox selected');
      },
      1
    );

    lendio.setEventHandler(
      'termsAndConditionsAccepted',
      () => {
        log('Lendio: Terms and conditions accepted');
      },
      1
    );
  });

  return (
    <>
      <Helmet>
        <title>Lendio - Business.LendingTree.com</title>
      </Helmet>
      <Modal
        centerContent={true}
        show={showErrorModal || isError}
        size="medium"
        title="An error occurred loading your experience"
        onClose={() => {
          navigate('/logout');
        }}
      >
        <div>
          <span>Redirecting...</span>
          <Loader centered={true} size="small" />
        </div>
      </Modal>
    </>
  );
};

export default LendioLanding;
