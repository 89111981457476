import { LendioInit, LendioInitParams } from '~/features/Lendio/types/GlobalLendio';
import { borrowerPortalApi } from '.';

export const lendioApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLendioAppInitData: builder.query<LendioInit, LendioInitParams>({
      query: (params) =>
        `lendio/init?guid=${params.qFormGuid}&email=${encodeURIComponent(params.email)}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetLendioAppInitDataQuery } = lendioApi;
