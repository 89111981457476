import { LogMessageParams } from '~/features/Logger/types/LoggerParams';
import { borrowerPortalApi } from '.';

export const loggerApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    logMessage: builder.query<void, LogMessageParams>({
      query: (params) => ({
        url: `api/log/logMessage?guid=${params.qFormGuid}&message=${params.message}&id=${params.id}`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyLogMessageQuery } = loggerApi;
